<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 card px-0">
        <dx-data-grid
          id="amazonReportsGrid"
          ref="amazonGridRef"
          height="calc(100vh - 250px)"
          :data-source="amazonReportsDataSource"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @option-changed="onOptionChanged"
          @content-ready="onContentReady"
          @cell-dbl-click="onCellDblClick"
          @toolbar-preparing="onToolbarPreparing($event)"
          @editor-preparing="onEditorPreparing"
        >
          <dx-search-panel
            :highlight-search-text="true"
            :visible="true"
            :width="200"
            placeholder="Search..."
          />
          <dx-remote-operations :paging="true" :filtering="true" :sorting="true" />
          <dx-selection
            show-check-boxes-mode="always"
            select-all-mode="page"
            :allow-select-all="true"
            :width="10"
            mode="multiple"
          />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <dx-column :visible="!(selectedReportType === 'CATEGORY' || selectedReportType === 'SUPPLIER')" data-field="smallImage" cell-template="imageCellTemplate" :width="100" caption="Image" alignment="center" :allow-sorting="false" />
          <template #imageCellTemplate="{data}">
            <p-lazy-img width="72px" height="72px" :src="data.value || defaultImg" />
          </template>
          <dx-column :visible="!(selectedReportType === 'CATEGORY' || selectedReportType === 'SUPPLIER')" data-field="title" cell-template="titleCellTemplate" :min-width="300" />
          <template #titleCellTemplate="{data}">
            <span class="dx-product-title text-info" role="button" @click="openProductDetails(data.data)">
              {{ data.value }}
            </span>
          </template>
          <dx-column :visible="selectedReportType === 'CATEGORY'" data-field="productCategory" :min-width="300" />
          <dx-column :visible="selectedReportType === 'SUPPLIER'" data-field="supplier" :min-width="300" cell-template="supplierTemplate" />
          <template #supplierTemplate="{data}">
            <div>
              <a :id="`supplier-${data.data.id}`" href="#" class="link text-primary" @click="toggleSupplierPopover(data)">
              <span class="underline_txt">
                {{ data.value }}
              </span>
              </a>
            </div>
          </template>

          <dx-column :visible="!(selectedReportType === 'CATEGORY' || selectedReportType === 'SUPPLIER')" data-field="amzRank" cell-template="rankingCellTemplate" caption="AMZ Rank" alignment="center" :allow-sorting="false" width="100" />
          <template #rankingCellTemplate="{data}">
            {{ data.value || '-' }}
          </template>
          <dx-column :visible="selectedReportType === 'ASIN'" data-field="asin" caption="ASIN" :width="120" cell-template="asinCellTemplate" />
          <template #asinCellTemplate="{data}">
            <a target="_blank" :href="`https://www.amazon.com/gp/product/${data.value}`">
              {{ data.value }}
            </a>
          </template>
          <dx-column :visible="selectedReportType === 'MSKU'" data-field="msku" caption="MSKU" />
          <dx-column data-field="itemCount" caption="Order Qty" :width="110" />
          <dx-column data-field="costPerUnit" caption="Amazon Fees" cell-template="costPerUnitTemplate" />
          <template #costPerUnitTemplate="{data}">
            <div>
              <a :id="`cost-${data.data.id}`" href="#" class="link text-primary" @click="togglePopover(data, 'otherCosts')">
              <span class="underline_txt">
                {{ getCurrencyFormat(data.value) }}
              </span>
              </a>
            </div>
          </template>
          <dx-column data-field="mfnShipping" caption="MFN Shipping" :width="140" :format="{ type: 'currency', precision: 2 }" />
          <dx-column data-field="salePricePerUnit" caption="AVG Unit Price" :width="140" :allow-sorting="false" :format="{ type: 'currency', precision: 2 }" />
          <dx-column data-field="revenue" caption="Revenue" :width="140" :format="{ type: 'currency', precision: 2 }" />
          <dx-column caption="Gross Income">
            <dx-column data-field="profit" :width="140" cell-template="profitTemplate" />
            <dx-column data-field="roi" caption="ROI %" :width="90" alignment="right" cell-template="roiTemplate" />
          </dx-column>
          <template #profitTemplate="{data}">
            <div :class="resolveByValue(data.value)">
              {{ getCurrencyFormat(data.value) }}
            </div>
          </template>
          <template #roiTemplate="{ data }">
            <div :class="resolveByValue(data.value)">
              {{ data.value }}%
            </div>
          </template>
          <template #inboundInfo="{ data }">
            <div :id="data.column.dataField">
              <span v-b-tooltip.d100.hover.bottom.v-primary title="Total items in-transit to the warehouse">
                {{ data.column.caption }}
              </span>
              <i class="bi bi-question-circle text-warning" />
            </div>
          </template>
          <template #warehouseInfo="{ data }">
            <div :id="data.column.dataField">
              <span v-b-tooltip.d100.hover.bottom.v-primary title="Items in the warehouse inventory">
                {{ data.column.caption }}
              </span>
              <i class="bi bi-question-circle text-warning" />
            </div>
          </template>
          <template #amazonInfo="{ data }">
            <div :id="data.column.dataField">
              <span v-b-tooltip.d100.hover.bottom.v-primary title="Total items includes Amazon inventory, inbound shipped, inbound receiving and reserved">
                {{ data.column.caption }}
              </span>
              <i class="bi bi-question-circle text-warning" />
            </div>
          </template>
          <template #quantityTemplate="{data}">
            <div :id="`qty-${data.data.id}`">
              <span :class="`${!data.value || data.value === 0 ? '' : 'badge badge-light-success'}`"> {{ data.value }} </span>
            </div>
          </template>
          <dx-column caption="On Hand Quantities" alignment="center">
            <dx-column data-field="inboundQty" :width="140" caption="Inbound" cell-template="quantityTemplate" header-cell-template="inboundInfo" />
            <dx-column data-field="warehouseQty" :width="140" caption="Warehouse" cell-template="quantityTemplate" header-cell-template="warehouseInfo" />
            <dx-column data-field="amazonQty" :width="140" caption="Amazon" cell-template="quantityTemplate" header-cell-template="amazonInfo" />
          </dx-column>
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="100" :allow-sorting="false" :fixed="true" fixed-position="left" />
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                type="default" icon="icon bi-bar-chart-steps"
                class="mr-half" hint="Profitibility Graph"
                @click="openItemChart(data)"
              />
            </div>
          </template>
          <template #toolbarFilters>
            <div class="d-flex">
              <div v-if="hasPermission" class="mr-1">
                <dx-util-text-box
                  v-model.trim="accountNo"
                  :show-clear-button="true"
                  mode="text"
                  placeholder="Account or Suite No"
                  @key-down="onFilterByAccountNo"
                  @value-changed="onFilterByAccountNo"
                />
              </div>
              <div class="mr-1">
                <dx-util-select-box
                  v-model="selectedStore"
                  :data-source="stores"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <date-selection default-date="current_month" @setdates="onDateSelection" />
              <div>
                <dx-util-button v-if="selectedReportType === 'CATEGORY'" text="Show Overview" type="default" @click="openCategoryOverview" />
              </div>
            </div>
          </template>
        </dx-data-grid>
        <div class="card m-half d-flex flex-row justify-content-between border-top" style="padding-inline: 4rem;padding-top: 4px;">
          <div class="text-center">
            <strong class="text-muted">
              Units Sold
            </strong>
            <h3 class="">
              {{ sumData.itemCount }}
            </h3>
          </div>
          <div class="text-center">
            <strong class="text-muted">
              Revenue
            </strong>
            <h3>
              {{ getCurrencyFormat(sumData.revenue) }}
            </h3>
          </div>
          <div class="text-center">
            <strong class="text-muted">
              Gross Profit
            </strong>
            <h3 :class="resolveSumValue(sumData.profit)">
              {{ getCurrencyFormat(sumData.profit) }}
            </h3>
          </div>
          <div class="text-center">
            <strong class="text-muted">
              Gross ROI
            </strong>
            <h3 :class="resolveSumValue(sumData.roi)">
              {{ sumData.roi }}%
            </h3>
          </div>
          <div class="text-center">
            <strong class="text-muted">
              On Hand Quantity
            </strong>
            <h3>
              {{ onHand }}
            </h3>
          </div>
          <div id="onHandValue" class="text-center">
            <strong class="text-info">
              On Hand Value
            </strong>
            <h3>
              {{ getCurrencyFormat(sumData.onHandValue) }}
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 px-0">
      <light-box
        :items="images"
        :index="index"
        :effect="'fade'"
        @close="index = null"
      />
    </div>
    <!--Begin:: Product Details -->
    <product-details :product-id="selectedProductId"
      :show-product="isProductDetailsVisible"
      @close="isProductDetailsVisible=false,selectedProductId=null"
    />
    <stacked-bar-chart :component-id="stackedChartCompId" :chart-data="chartData" />
    <sankey-chart :component-id="sankeyChartCompId" :chart-data="categoryOverviewData" />
    <line-chart :component-id="lineChartCompId" :store-id="storeId" :report-item="reportItem" :report-type="selectedReportType" />
    <dx-util-popover
      target="#onHandValue"
      position="top"
      show-event="dxhoverstart"
      hide-event="dxhoverend"
      :show-title="true"
      title="On Hand Details"
    >
      <template>
        <div>
          <table>
            <thead>
              <tr>
                <th>Inventory</th>
                <th>Quantity</th>
                <th>Total Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Warehouse Inbound
                </td>
                <td>
                  <span v-if="sumData.inboundQty">
                    {{ sumData.inboundQty.toLocaleString() }}
                  </span>
                </td>
                <td>
                  {{ getCurrencyFormat(sumData.inboundSalePrice) }}
                </td>
              </tr>
              <tr>
                <td>
                  Warehouse Inventory
                </td>
                <td>
                  <span v-if="sumData.warehouseQty">
                    {{ sumData.warehouseQty.toLocaleString() }}
                  </span>
                </td>
                <td>
                  {{ getCurrencyFormat(sumData.inventorySellPrice) }}
                </td>
              </tr>
              <tr>
                <td>
                  Amazon Inventory (Including AMZ Inbound)
                </td>
                <td>
                  <span v-if="sumData.amazonQty">
                    {{ sumData.amazonQty.toLocaleString() }}
                  </span>
                </td>
                <td>
                  {{ getCurrencyFormat(sumData.amazonSellPrice) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </dx-util-popover>
    <!--End:: Product Details -->
    <dx-util-popover
      :target="hoveredItem"
      position="right"
      :defer-rendering="false"
      :close-on-outside-click="true"
      :show-title="true"
      title="Cost Details"
      :width="400"
      :visible="isPopoverVisible"
      :show-close-button="true"
      @hiding="closePopover"
    >
      <template>
        <div>
          <cost-snapshot :cost-details="costDetails" />
        </div>
      </template>
    </dx-util-popover>
    <dx-util-popover
      :target="hoveredSupplier"
      position="right"
      :defer-rendering="false"
      :close-on-outside-click="true"
      :show-title="true"
      title="Supplier Details"
      :width="400"
      :visible="isSupplierPopoverVisible"
      :show-close-button="true"
      @hiding="closePopover"
    >
      <template>
        <div>
          <supplier-snapshot :supplier="selectedSupplier" :product-list="productList" />
        </div>
      </template>
    </dx-util-popover>
    <div class="col-md-12 py-1 position-absolute fixed-bottom">
      <div class="d-flex flex-row justify-content-start align-items-center">
        <p-icon name="bi-info-circle-fill" color="warning" size="32px" class="mr-1" />
        <span><strong>Beta Version: This is a beta version of this report module. It is currently being tested and its usage is for free. Some features may be limited upon the full version release and will be subject to additional charges.</strong></span>
      </div>
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import DateSelection from '@/views/dashboard/statistics/components/DateSelection.vue'
import { ReportTypeEnum } from '@/enums'
import AmazonReportFilter from '@/http/models/search-filters/AmazonReportFilter'
import { currencyFormatter } from '@core/utils/filter'
import amazonReportService from '@/http/requests/reports/amazonReportService'
import { Notify } from '@/@robustshell/utils'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { AmazonReportsDataSource } from './amazonReportsStore'

// import Pager from '@core/dev-extreme/utils/pager'
// import ReportDateRangeEnum from '@/enums'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    'cost-snapshot': () => import('./CostSnapshot.vue'),
    'supplier-snapshot': () => import('./SupplierSnapshot.vue'),
    'product-details': () => import('@/views/apps/product/products/components/ProductDetails.vue'),
    'stacked-bar-chart': () => import('./StackedBarChart.vue'),
    'sankey-chart': () => import('./SankeyChart.vue'),
    'line-chart': () => import('./LineChart.vue'),
    'light-box': () => import('vue-cool-lightbox'),
    'date-selection': DateSelection,
  },
  mixins: [GridBase, Filters],
  data() {
    return {
      accountNo: '',
      selectedReportType: ReportTypeEnum.ASIN.value,
      amazonReportsDataSource: AmazonReportsDataSource,
      q: '',
      beginDate: null,
      endDate: null,
      defaultImg: defaultImageUrl,
      selectedProductId: null,
      isProductDetailsVisible: false,
      hoveredItem: '',
      hoveredSupplier: '',
      isPopoverVisible: false,
      isSupplierPopoverVisible: false,
      images: [],
      index: null,
      chartData: null,
      stackedChartCompId: '',
      sankeyChartCompId: '',
      lineChartCompId: '',
      supplierChartCompId: '',
      supplierChartData: {},
      selectedSupplier: '',
      productList: [],
      storeId: null,
      reportItem: '',
      categoryOverviewData: [],
      costDetails: {
        fbaFee: null,
        referralFee: null,
        prepFee: null,
        planFee: null,
        shippingFee: null,
        miscFee: null,
      },
      supplierDetails: [],
      sumData: {},
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    dataGrid() {
      const dataGridIns = this.$refs.amazonGridRef.instance
      return dataGridIns
    },
    dataSource() {
      const dataSource = this.dataGrid.getDataSource()
      return dataSource
    },
    onHand() {
      const totalQuantity = (parseInt(this.sumData.warehouseQty, 10) || 0) + (parseInt(this.sumData.amazonQty, 10) || 0) + (parseInt(this.sumData.inboundQty, 10) || 0)
      return totalQuantity.toLocaleString()
    },
  },
  watch: {
    '$route.params': {
      handler() {
        this.setRouteParams()
        this.dataGrid.clearSorting()
        this.searchByFilter()
      },
    },
  },
  created() {
    this.setRouteParams()
    this.getStores()
  },
  mounted() {
    this.loadData()
    this.getSumReport()
  },
  methods: {
    setRouteParams() {
      this.selectedReportType = this.$route.params.reportType
    },
    getCurrencyFormat(value) {
      return currencyFormatter(value)
    },
    formatDate(date) {
      if (!moment(date).isValid()) {
        return 'Select Date'
      }
      return moment(date).format('YYYY-MM')
    },
    openProductDetails(e) {
      this.selectedProductId = e.productId
      this.isProductDetailsVisible = true
    },
    openItemChart(e) {
      this.storeId = e.data.storeId
      if (this.selectedReportType === ReportTypeEnum.ASIN.value) {
        this.reportItem = e.data.asin
      }
      if (this.selectedReportType === ReportTypeEnum.MSKU.value) {
        this.reportItem = e.data.msku
      }
      if (this.selectedReportType === ReportTypeEnum.CATEGORY.value) {
        this.reportItem = e.data.productCategory
      }
      if (this.selectedReportType === ReportTypeEnum.SUPPLIER.value) {
        this.supplierChartData = e.data
      }

      this.lineChartCompId = uuidv4()
    },
    openCategoryOverview() {
      // this.categoryOverviewData = this.dataSource
      this.sankeyChartCompId = uuidv4()
    },
    loadData() {
      AmazonReportFilter.setDefaultFilters()
      AmazonReportFilter.reportType = this.selectedReportType
      AmazonReportFilter.beginDate = this.beginDate
      AmazonReportFilter.endDate = this.endDate
      const filters = AmazonReportFilter.getFilters()
      AmazonReportsDataSource.searchValue(filters)
      AmazonReportsDataSource.load().done(data => {
        this.categoryOverviewData = data
      })
    },
    searchByFilter() {
      AmazonReportFilter.accountNo = this.accountNo
      AmazonReportFilter.storeId = this.selectedStore
      AmazonReportFilter.reportType = this.selectedReportType
      AmazonReportFilter.beginDate = this.beginDate
      AmazonReportFilter.endDate = this.endDate
      AmazonReportFilter.q = this.q
      const filters = AmazonReportFilter.getFilters()
      AmazonReportsDataSource.searchValue(filters)
      AmazonReportsDataSource.reload()
      this.getSumReport()
      AmazonReportsDataSource.load().done(data => {
        this.categoryOverviewData = data
      })
    },
    onDateSelection(e) {
      this.beginDate = e.beginDate
      this.endDate = e.endDate
      this.searchByFilter()
    },
    resetAndRefreshDataGrid() {
      this.accountNo = ''
      this.selectedStore = 0
      this.q = ''
      const filters = AmazonReportFilter.getFilters()
      this.dataSource.searchValue(filters)
      this.dataSource.reload()
      AmazonReportsDataSource.load().done(data => {
        this.categoryOverviewData = data
      })
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value.toString()
          this.getStores(this.accountNo)
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = ''
        this.selectedStore = 0
        this.searchByFilter()
      }
    },
    resolveByValue(value) {
      const profit = parseInt(value, 10)
      if (profit >= 0) {
        return 'badge badge-light-success'
      }
      return 'badge badge-light-danger'
    },
    resolveSumValue(value) {
      const profit = parseInt(value, 10)
      if (profit >= 0) {
        return 'text-success'
      }
      return 'text-danger'
    },
    togglePopover(data, costType) {
      const rowId = data.data.id
      this.hoveredItem = `#cost-${rowId}`
      const payload = {
        unitCOGS: data.data.unitCOGS,
        fbaFee: data.data.fbafeePerUnit,
        referralFee: data.data.referralFeePerUnit,
        prepFee: data.data.prepFeePerUnit,
        planFee: data.data.planFeePerUnit,
        shippingFee: data.data.shippingFeePerUnit,
        miscFee: data.data.miscFee,
        costType: costType,
      }

      this.isPopoverVisible = true
      this.costDetails = payload
    },
    toggleSupplierPopover(data) {
      const rowId = data.data.id
      this.hoveredSupplier = `#supplier-${rowId}`
      const payload = data.data.productList
      this.isSupplierPopoverVisible = true
      this.productList = [...payload]
      this.selectedSupplier = data.data.supplier
    },
    closePopover() {
      this.isPopoverVisible = false
      this.isSupplierPopoverVisible = false
    },
    async getSumReport() {
      const filters = AmazonReportFilter.getFilters()
      const response = await amazonReportService.getAmazonSumReport(filters)
      this.sumData = response
    },
    // DATAGRID METHODS
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        this.dataSource.pageIndex(0)
      }
    },
    onCellDblClick(e) {
      if (e.rowType === 'data' && e.column.dataField === 'smallImage') {
        if (e.eventType === 'dxdblclick') {
          if (e.data.largeImage) {
            this.images.length = 0
            this.index = 0
            this.images.push(e.data.largeImage)
          }
        }
      }
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.q = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.q = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.q = ''
            this.searchByFilter()
          }
        }
      }
    },
    async onDownloadReport(e) {
      AmazonReportFilter.itemAction = this.isAll ? 'ALL' : this.itemAction
      const filters = AmazonReportFilter.getFilters()
      const pageableQuery = 'page=0&size=10000'
      const response = await amazonReportService.getAmazonReport(pageableQuery, filters)
      const data = response.content
      this.onDownloadExportData(data)
    },
    onDownloadReportSelected() {
      const data = this.dataGrid.getSelectedRowsData()
      if (Array.isArray(data) && data.length > 0) {
        this.onDownloadExportData(data)
      } else {
        Notify.warning('Please select at least one item to export data')
      }
    },
    onDownloadExportData(data) {
      const fileDate = new Date().toDateString()
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet(`Inventory ${fileDate}`)
      worksheet.columns = [
        { header: 'Title', key: 'title', width: 100 },
        { header: 'Amz Rank', key: 'amzRank', width: 14 },
        { header: 'ASIN', key: 'asin', width: 14 },
        { header: 'Item Count', key: 'itemCount', width: 14 },
        { header: 'Cost Per Unit', key: 'costPerUnit', width: 14 },
        { header: 'MFN Shipping', key: 'mfnShipping', width: 14 },
        { header: 'Revenue', key: 'revenue', width: 14 },
        { header: 'Profit', key: 'profit', width: 10 },
        { header: 'ROI', key: 'roi', width: 12 },
        { header: 'Inbound QTY', key: 'inboundQty', width: 18 },
        { header: 'Warehouse QTY', key: 'warehouseQty', width: 18 },
        { header: 'Amazon QTY', key: 'amazonQty', width: 18 },
      ]
      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:N1'
      worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 1 }]

      data.forEach(item => {
        worksheet.addRow([
          item.title,
          item.amzRank,
          item.asin,
          item.itemCount,
          item.costPerUnit,
          item.mfnShipping,
          item.revenue,
          item.profit,
          item.roi,
          item.inboundQty,
          item.warehouseQty,
          item.amazonQty,
        ])
      })
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `${this.selectedReportType} Profitability ${fileDate}.xlsx`,
        )
      })
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)

      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          text: 'Export',
          icon: 'exportxlsx',
          items: [
            {
              id: 'all',
              name: 'All Items',
              icon: 'selectall',
            },
            {
              id: 'selected',
              name: 'Selected',
              icon: 'exportselected',
            },
          ],
          width: 140,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Download All Report',
          onItemClick: arg => {
            if (arg) {
              //
            }
            if (arg.itemData.id === 'all') {
              this.onDownloadReport()
            } else if (arg.itemData.id === 'selected') {
              this.onDownloadReportSelected()
            }
          },
        },

        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.resetAndRefreshDataGrid()
            this.getSumReport()
          },
        },
        location: 'after',
      })
    },
  },

}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  min-width: 250px;
  border-collapse: collapse;
}

thead {
  background-color: rgba(0, 0, 0, 0.1);
}

th {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

td {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
td:nth-child(2) {
  text-align: end;
}
</style>
