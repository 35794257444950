/* eslint-disable lines-between-class-members */
import { ReportDateRangeEnum } from '@/enums'
import BaseSearchFilter from './BaseSearchFilter'

export class AmazonReportFilter extends BaseSearchFilter {
  /** @type {string} */
  #reportType = ''
  #reportDateRange = ''
  constructor() {
    super()
    this.#reportType = this.reportType
    this.#reportDateRange = this.reportDateRange
  }

  setDefaultFilters() {
    this.accountNo = ''
    this.companyId = 0
    this.storeId = 0
    this.warehouseId = 0
    this.beginDate = null
    this.endDate = null
    this.tenantType = 'SERVING'
    this.q = ''
    this.reportType = ''
    this.reportDateRange = ReportDateRangeEnum.LAST_MONTH.value
  }

  /** @returns {Object} filter object */
  getFilters() {
    const filter = {
      accountNo: this.accountNo,
      companyId: this.companyId,
      storeId: this.storeId,
      warehouseId: this.warehouseId,
      beginDate: this.beginDate,
      endDate: this.endDate,
      tenantType: this.tenantType,
      q: this.q,
      reportType: this.reportType,
      reportDateRange: this.reportDateRange,
    }
    return filter
  }

  /** @returns {string} */
  get reportType() {
    return this.#reportType
  }
  /** @param {string} value */
  set reportType(value) {
    this.#reportType = value
  }
  /** @returns {string} */
  get reportDateRange() {
    return this.#reportDateRange
  }
  /** @param {string} value */
  set reportDateRange(value) {
    this.#reportDateRange = value
  }
}
export default new AmazonReportFilter()
